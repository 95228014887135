import { getIpInfo } from "@/apis/ipInfo";
import { IpInfoResponse } from "@/helpers/interfaces/shared";
import { signOut } from "aws-amplify/auth";
import { usePiniaStore } from "@/plugins/store";
import { redirectToRoute } from "@/plugins/router";
import { routeNames } from "@/constants/routeNames";

export const isMobileDevice = () => {
    /**
     * Check the device used by the user.
     * @return {[boolean]}
     */

    return /iPhone|iPad|iPod|Android|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
    );
};

export const getUserIpAddress = async () => {
    /**
     * Calls ipinfo.io to retrieve the user's IP address.
     * Uses the ipinfo.io API, which is limited to 50,000 requests per month in the free tier.
     */
    let userIpAddress = "";
    const data: IpInfoResponse = await getIpInfo();

    if (data !== null) {
        userIpAddress = "ip" in data ? (data.ip as string) : "";
    }

    return userIpAddress;
};

export const handleLogout = async () => {
    const pStore = usePiniaStore();

    await signOut();
    pStore.setIsAuthenticated(false);
    if (pStore.getOrigin === routeNames.E_MANDATES_LANDING_PAGE) {
        redirectToRoute(routeNames.E_MANDATES_LANDING_PAGE, {
            shortcode: pStore.getShortcode,
            accountAlias: pStore.getAccountAlias,
        });
    } else {
        const formName = pStore.getFormName ?? "invoice";
        redirectToRoute(routeNames.INVOICE, {
            shortcode: pStore.getShortcode,
            form: formName,
        });
    }
};
