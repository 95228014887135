import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { usePiniaStore } from "@/plugins/store";
import { routeNames } from "@/constants/routeNames";
import { captureEvent } from "@/plugins/posthog";

const mainRoutes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: routeNames.DEFAULT,
        component: () => import("@/views/default/NotFoundPage.vue"),
    },
    {
        path: "/:shortcode",
        // TODO improve the experience for users the 2nd time they use it and are already "logged in"
        name: routeNames.INVOICE,
        component: () => import("@/views/LoginPage.vue"),
        meta: { checkShortcode: true },
    },
    {
        path: "/:shortcode/:form",
        // TODO improve the experience for users the 2nd time they use it and are already "logged in"
        name: routeNames.FORM,
        component: () => import("@/views/LoginPage.vue"),
        meta: { checkShortcode: true, checkFormName: true },
    },
    {
        path: "/:shortcode/:form/supplementary-form",
        // TODO improve the experience for users the 2nd time they use it and are already "logged in"
        name: routeNames.SUPPLEMENTARY_FORM,
        component: () => import("@/views/SupplementaryFormPage.vue"),
        meta: {
            requiresFormStructure: true,
            checkShortcode: true,
            checkFormName: true,
        },
    },
    /** Url for one time links */
    {
        path: "/:shortcode/:form/one-time-link/:id",
        name: routeNames.FORM_PAYMENT,
        component: () => import("@/views/FormPayment.vue"),
        meta: {
            checkShortcode: true,
        },
    },
    {
        path: "/:shortcode/:form/payment",
        name: routeNames.PAYMENT_METHOD,
        component: () =>
            import("@/views/payment-methods/PaymentMethodPage.vue"),
        meta: {
            requiresPaymentMethods: true,
            checkShortcode: true,
            checkFormName: true,
        },
    },
    {
        path: "/:shortcode/:form/payment/transfer/:id",
        name: routeNames.TRANSFER_DETAILS,
        component: () =>
            import("@/views/providers/bank-transfer/TransferDetails.vue"),
        meta: {
            requiresPaymentMethods: true,
            checkShortcode: true,
            checkFormName: true,
            checkId: true,
        },
    },
    {
        path: "/:shortcode/:form/payment-methods-disabled",
        name: routeNames.DISABLED_PAYMENT_METHODS,
        component: () =>
            import("@/views/payment-methods/DisabledPaymentMethods.vue"),
        meta: {
            requiresPaymentMethods: true,
            checkShortcode: true,
            checkFormName: true,
        },
    },
    {
        path: "/:catchAll(.*)*",
        name: routeNames.NOT_FOUND,
        component: () => import("@/views/default/NotFoundPage.vue"),
    },
    {
        path: "/opayo-3ds-iframe",
        name: routeNames.OPAYO_IFRAME,
        component: () => import("@/views/providers/cards/OpayoIframe.vue"),
    },
    {
        path: "/:shortcode/:form/payment/status/:id",
        name: routeNames.PAYMENT_STATUS,
        component: () => import("@/views/PaymentStatus.vue"),
        meta: { checkShortcode: true, checkFormName: true, checkId: true },
    },
    {
        path: "/unauthorised",
        name: routeNames.ACCESS_DENIED,
        component: () => import("@/views/default/AccessDeniedPage.vue"),
    },
    {
        path: "/terms-and-conditions",
        name: routeNames.TERMS_AND_CONDITIONS,
        component: () => import("@/views/default/TermsAndConditions.vue"),
    },
    {
        path: "/privacy-policy",
        name: routeNames.PRIVACY_POLICY,
        component: () => import("@/views/default/PrivacyPolicy.vue"),
    },
    {
        path: "/:shortcode/payment-request",
        name: routeNames.OLD_PAYMENT_REQUEST_LINK,
        component: () => import("@/views/OldPaymentRequestLink.vue"),
        meta: {
            checkShortcode: true,
            checkFormName: false,
            checkId: false,
            requiresRedirectToNewLink: true,
        },
    },
    {
        path: "/reeds",
        name: routeNames.REEDS_LANDING_PAGE,
        component: () => import("@/views/static/ReedsLandingPage.vue"),
        meta: { checkShortcode: false, checkFormName: false, checkId: false },
    },
    // E-mandates
    {
        path: "/:shortcode/e-mandates/:accountAlias",
        name: routeNames.E_MANDATES_LANDING_PAGE,
        component: () => import("@/views/LoginPage.vue"),
        meta: {
            checkShortcode: true,
            checkFormName: false,
            requiresAccountAlias: true,
        },
    },
    {
        path: "/:shortcode/e-mandates/:accountAlias/form",
        name: routeNames.E_MANDATES_FORM,
        component: () => import("@/views/EMandatesForm.vue"),
        meta: {
            checkShortcode: true,
            checkFormName: false,
            requiresAccountAlias: true,
        },
    },
    {
        path: "/:shortcode/e-mandates/:accountAlias/confirmation",
        name: routeNames.E_MANDATES_CONFIRMATION,
        component: () => import("@/views/EMandatesConfirmation.vue"),
        meta: {
            checkShortcode: true,
            checkFormName: false,
            requiresAccountAlias: true,
        },
    },
    {
        path: "/auth/redirect",
        name: routeNames.AUTH_REDIRECT,
        component: () => import("@/views/auth/Redirect.vue"),
        meta: { checkShortcode: false, checkFormName: false },
    },
    {
        path: "/auth/sign-out",
        name: routeNames.AUTH_SIGN_OUT,
        component: () => import("@/views/auth/SignOut.vue"),
        meta: { checkShortcode: false, checkFormName: false },
    },
];

const routes = mainRoutes;

const router = createRouter({
    history: createWebHistory(),
    routes,
});

/**
 * The ts-ignore comment below was added because on build the compiler will throw this
 * typescript error: TS6133: 'from' is declared but its value is never read.
 * But the router plugin requires all 3 parameters to be passed in.
 *  */
// @ts-ignore
router.beforeEach((to, from, next) => {
    const pStore = usePiniaStore();

    if (from.path !== to.path) {
        captureEvent("$pageleave");
    }

    /** Gets the meta properties associated to the route */
    const requiresFormStructure = to.matched.some(
        (x) => x.meta.requiresFormStructure,
    );
    const requiresPaymentMethods = to.matched.some(
        (x) => x.meta.requiresPaymentMethods,
    );
    const requiresShortcodeParam = to.matched.some(
        (x) => x.meta.checkShortcode,
    );
    const requiresFormNameParam = to.matched.some((x) => x.meta.checkFormName);
    const requiresIdParam = to.matched.some((x) => x.meta.checkId);
    const requiresRedirectToNewLink = to.matched.some(
        (x) => x.meta.requiresRedirectToNewLink,
    );
    const requiresAccountAlias = to.matched.some(
        (x) => x.meta.requiresAccountAlias,
    );

    /**
     * Redirects to 404 if the route requiresShortcodeParam and the
     * shortcode param is null or undefined
     */
    if (requiresShortcodeParam) {
        if (["null", "undefined"].includes(String(to.params.shortcode))) {
            next("/");
            return;
        }
    }

    /**
     * Redirects to 404 if the route requiresFormNameParam and the
     * form name param is null or undefined
     */
    if (requiresFormNameParam) {
        if (["null", "undefined"].includes(String(to.params.form))) {
            next("/");
            return;
        }
    }

    /**
     * Redirects to 404 if the route requiresIdParam and the
     * id param is null or undefined
     */
    if (requiresIdParam) {
        if (["null", "undefined"].includes(String(to.params.id))) {
            next("/");
            return;
        }
    }

    /**
     * Redirects to the first page if the route requiresFormStructure and
     * the form structure in the session is null
     */
    if (requiresFormStructure) {
        if (pStore.getFormStructure === null && from.name !== "Form") {
            next(`/${to.params.shortcode}/${to.params.form}`);
            return;
        }
    }

    /**
     * Redirects to the first page if the route requiresPaymentMethods and
     * the payment method list in the session is null
     */
    if (requiresPaymentMethods) {
        if (pStore.getPaymentMethods === null && from.name !== "Form") {
            next(`/${to.params.shortcode}/${to.params.form}`);
            return;
        }
    }

    /**
     * Redirects to the form page if the route requiresRedirectToNewLink and
     * it adds the payment_reference param as the form name
     */
    if (requiresRedirectToNewLink) {
        next(`/${to.params.shortcode}/${to.query.payment_reference}`);
        return;
    }

    /**
     * Redirects to 404 if the route requiresAccountAlias and the
     * accountAlias param is null or undefined
     */
    if (requiresAccountAlias) {
        if (["null", "undefined"].includes(String(to.params.accountAlias))) {
            next("/");
            return;
        }
    }

    next();
});

router.afterEach((to) => {
    captureEvent("$pageview", { path: to.path });
});

export const redirectTo404 = () => {
    router.push({ name: routeNames.NOT_FOUND });
};

export const redirectToAccessDenied = () => {
    router.push({ name: routeNames.ACCESS_DENIED });
};

export const redirectToPaymentStatus = (
    shortcode: string,
    formName: string,
    id: string,
) => {
    router.push({
        name: routeNames.PAYMENT_STATUS,
        params: {
            shortcode: shortcode,
            form: formName,
            id: id,
        },
    });
};

export const redirectToPath = (path: string) => {
    router.push(path);
};

export const redirectToRoute = (name: string, params: any) => {
    router.push({
        name: name,
        params: params,
    });
};

export default router;
